import React, { useState } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export const pageQuery = graphql`
        query {
            allStrapiJob {
                edges {
                    node {
                        seo {
                            metaTitle
                            metaDescription
                            shareImage {
                              url
                            }
                        },
                        job {
                            title,
                            address,
                            duties
                        },
                        bottom {
                            title
                            info
                            link
                            image {
                                localFile {
                                    childImageSharp {
                                      gatsbyImageData(width: 3840, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                    }
                                  }
                            }
                        }
                    }
                  }
            }
        }`
    

export default function Job({ data,location }) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const node = data.allStrapiJob.edges[0].node
    const [fileName,setFileName] = useState('')

    const [submitLoading,setSubmitLoading] = useState(false)
    const submitFun = (e)=>{
        e.preventDefault()
        const name = e.target.querySelector("[name='name']").value || ''
        const surname = e.target.querySelector("[name='surname']").value || ''
        const city = e.target.querySelector("[name='city']").value || ''
        const email = e.target.querySelector("[name='email']").value || ''
        const phone = e.target.querySelector("[name='tel']").value || ''
        const weixin = e.target.querySelector("[name='weixin']").value || ''
        const file = e.target.querySelector("[name='file']").files[0]
        const fd = new FormData()
        fd.append('files.file', file)
        fd.append('data', JSON.stringify({name,surname,city,email,phone,weixin}))
        
        setSubmitLoading(true)
        fetch('https://admin.cnsotto.com/job-application-forms',{
            method:'POST',
            mode:'cors',
            cache:'default',
            body: fd
        })
        .then(res =>res.json())
        .then((data) => {
            setSubmitLoading(false)
            if(data.id){
                Toastify({
                    text: `你的申请表提交成功!`,
                    position:"center"
                }).showToast();
            }
        })
    }
    
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            
            <div className="container mx-auto pt-[193px] pb-[140px] sm:pb-0 sm:pt-28">
                <div className="flex justify-between items-end pb-[100px] sm:flex-col sm:items-start sm:pb-14">
                    <h1 className="text-[40px] leading-snug sm:mb-8">取得联系</h1>
                    <ul className="flex items-center text-gray flex-wrap sm:w-full sm:justify-center">
                        <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/contact">联系方式</a></li>
                        <li className="mr-[38px] sm:mr-5"><a className="text-black" href="/job">工作机会</a></li>
                        <li className="mr-[38px] sm:mr-5"><a className="hover:text-black" href="/supplier">供应商体系</a></li>
                        <li><a href="/sale-service" className="hover:text-black">售后服务</a></li>
                    </ul>
                </div>
            </div>
            <div className="bg-light">
                <div className="container mx-auto flex justify-between py-[94px] xl:flex-col sm:py-10">
                    <h2 className="text-3xl leading-snug items-start xl:mb-8">
                        工作机会
                    </h2>
                    <div className="w-[940px] xl:w-full">
                        <div className="font-semibold flex items-center pb-[6px] border-b-[2px] border-black">
                            <div className="w-[40%]">工作岗位</div>
                            <div className="w-[60%]">工作地点</div>
                        </div>
                        {
                        node.job.map((n,i)=>{
                                return (
                                    <details key={i}>
                                        <summary>
                                            <div className="w-[40%]">{n.title}</div>
                                            <div className="w-[60%]">{n.address}</div>
                                        </summary>
                                        <div className="pb-10">
                                            <div className="font-semibold pb-[14px]">职位要求</div>
                                            <article dangerouslySetInnerHTML={{__html: n.duties}}></article>
                                        </div>
                                    </details>
                                )
                            })
                        }
                        
                    </div>
                </div>  
            </div>
              
            <div className="container mx-auto pt-[100px] pb-[140px] sm:py-10">    
                <div className="flex justify-between pb-[134px] xl:flex-col sm:pb-24">
                    <h2 className="text-3xl leading-snug items-start xl:mb-8">
                    申请表
                    </h2>
                    <form onSubmit={submitFun} className="grid grid-cols-3 gap-x-[50px] gap-y-10 w-[940px] xl:w-full sm:grid-cols-2 sm:gap-x-8">
                        <div>
                            <input type="text" name="name" placeholder="名字" required/>
                        </div>
                        <div>
                            <input type="text" name="surname" placeholder="姓氏" required/>
                        </div>
                        <div>
                            <input type="text" name="city" placeholder="城市" required/>
                        </div>
                        <div>
                            <input type="email" name="email" placeholder="电子邮箱" required/>
                        </div>
                        <div>
                            <input type="tel" name="tel" placeholder="手机" />
                        </div>
                        <div>
                            <input type="text" name="weixin" placeholder="微信号" />
                        </div>
                        <label className="col-span-3 hover:cursor-pointer relative sm:col-span-2">
                                {fileName?<div className="h-[42px] absolute w-full bg-white z-[1] text-lg">{fileName} <span className="text-sm text-gray-light ml-2">点击更换文件</span></div>:null}
                                <div className={fileName?'opacity-0':'opacity-100'}>
                                    <span className="inline-flex items-end w-[42px] h-[42px] relative bg-white mr-2"><svg width="42" height="42" className="absolute bg-white" fill="none"><path stroke="#000" strokeWidth="2" d="M1 1h40v40H1z"/><path d="M12.2 21.5h16.6M21.3 13v16" stroke="#000" strokeWidth="2"/></svg><input type="file" name="file" onChange={(e)=>{if(e.target.files?.length){setFileName(e.target.files[0].name)}}} required accept=".doc,.docx,.txt,.pdf" className="mr-1 opacity-0"/></span>
                                    添加简历附件
                                </div>
                        </label>
                        <div className="col-span-3 pt-6 sm:col-span-2">
                            <input type="submit" disabled={submitLoading} className="disabled:cursor-not-allowed disabled:opacity-50 w-[140px] h-10 flex justify-center items-center rounded-full border-[2px] border-black text-black bg-transparent hover:bg-black hover:text-white" value="申请"/>
                        </div>
                    </form>
                </div>
                <div className="flex justify-between items-start xl:flex-col xl:pb-28 xl:relative">
                    <div className="w-[380px] xl:w-full">
                      <h2 className="text-3xl leading-snug pb-5">{node.bottom.title}</h2>
                      <p className="pb-10">{node.bottom.info}</p>
                      <Button variant="black" href={node.bottom.link} className="xl:absolute xl:bottom-10">我们的地址</Button>
                    </div>
                    <GatsbyImage className="w-[940px] bg-light xl:w-full" image={getImage(node.bottom.image.localFile)} alt="banner"/>
                    
                </div>
            </div>
        </Layout>
    )
}

